define("incubex/services/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NotificationService extends Ember.Service {
    constructor() {
      super(...arguments);
      this.messages = [];
      this.toasts = [];
      this.delay = {
        auto: 2500,
        close: 250
      };
    }

    addMessage(text, type, countdown) {
      let message = {
        text,
        type,
        countdown
      };
      this.messages.insertAt(0, message);
    }

    removeMessage(message) {
      this.messages.removeObject(message);
    }

    addToast(text, countdown) {
      let toast = {
        text,
        countdown
      };
      this.toasts.pushObject(toast);
    }

    removeToast(toast) {
      this.toasts.removeObject(toast);
    }

    clearAllMessages() {
      this.messages.clear();
    }

    clearErrors() {
      let errors = this.messages.filterBy('type', 'error');
      this.messages.removeObjects(errors);
    }

    error(error) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        countdown: false
      };
      error = error.message || error;
      this.addMessage(error, 'error', options.countdown);
    }

    success(text) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        countdown: true
      };
      this.addMessage(text, 'success', options.countdown);
    }

    info(text) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        countdown: true
      };
      this.addMessage(text, 'info', options.countdown);
    }

    toast(text) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        countdown: false
      };
      this.addToast(text, options.countdown);
    }

  }

  _exports.default = NotificationService;
});