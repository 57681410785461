define("incubex/components/order-settings", ["exports", "@glimmer/component", "incubex/utils/order-enums"], function (_exports, _component, _orderEnums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#modal-dialog onClickOverlay= (action this.onCloseOrderSettings )}}
    <Button
      @style="link"
      @class="flex text-base items-center absolute top-16 right-16 font-bold"
      @onClick={{this.onCloseOrderSettings}}
    >
      {{svg-jar "close" height="12" width="12" class="ml-8 font-bold"}}
    </Button>
    <h1 class="text-lg ml-8 mt-16 mb-16 font-bold text-base">Order Defaults</h1>
    <Dropdown
      @class="m-8 block"
      @label="Time-in-Force"
      @role="time-in-force"
      @default="Time-in-Force"
      @options={{this.orderDurations}}
      @value={{this.defaultOrderDuration}}
    />
    <Dropdown
      @class="m-8 block"
      @label="Clearing Account"
      @role="clearing-account"
      @default="Clearing account"
      @options={{this.clearingAccounts}}
      @value={{this.defaultClearingAccount}}
    />
    <Input::Textbox
      @class="m-8"
      @label="Order Size"
      @value={{this.defaultOrderSize}}
      @name="default-order-size"
      @type="number"
      @min="1"
    />
  
    <div class="mt-24 flex  flex-row justify-end">
      <Button
        @style="link"
        @class="m-8 text-base mr-24"
        @onClick={{this.onCloseOrderSettings}}
        @name="close-order-settigs"
      >
        Cancel
      </Button>
      <Button
        @class="m-8"
        @name="save-order-settings"
        @onClick={{this.onSave}}
      >
        Done
      </Button>
    </div>
  {{/modal-dialog}}
  
  */
  {
    id: "Be5GnyyX",
    block: "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"onClickOverlay\"],[[28,\"action\",[[23,0,[]],[23,0,[\"onCloseOrderSettings\"]]],null]]],{\"statements\":[[0,\"  \"],[5,\"button\",[],[[\"@style\",\"@class\",\"@onClick\"],[\"link\",\"flex text-base items-center absolute top-16 right-16 font-bold\",[23,0,[\"onCloseOrderSettings\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"close\"],[[\"height\",\"width\",\"class\"],[\"12\",\"12\",\"ml-8 font-bold\"]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"text-lg ml-8 mt-16 mb-16 font-bold text-base\"],[8],[0,\"Order Defaults\"],[9],[0,\"\\n  \"],[5,\"dropdown\",[],[[\"@class\",\"@label\",\"@role\",\"@default\",\"@options\",\"@value\"],[\"m-8 block\",\"Time-in-Force\",\"time-in-force\",\"Time-in-Force\",[23,0,[\"orderDurations\"]],[23,0,[\"defaultOrderDuration\"]]]]],[0,\"\\n  \"],[5,\"dropdown\",[],[[\"@class\",\"@label\",\"@role\",\"@default\",\"@options\",\"@value\"],[\"m-8 block\",\"Clearing Account\",\"clearing-account\",\"Clearing account\",[23,0,[\"clearingAccounts\"]],[23,0,[\"defaultClearingAccount\"]]]]],[0,\"\\n  \"],[5,\"input/textbox\",[],[[\"@class\",\"@label\",\"@value\",\"@name\",\"@type\",\"@min\"],[\"m-8\",\"Order Size\",[23,0,[\"defaultOrderSize\"]],\"default-order-size\",\"number\",\"1\"]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"mt-24 flex  flex-row justify-end\"],[8],[0,\"\\n    \"],[5,\"button\",[],[[\"@style\",\"@class\",\"@onClick\",\"@name\"],[\"link\",\"m-8 text-base mr-24\",[23,0,[\"onCloseOrderSettings\"]],\"close-order-settigs\"]],{\"statements\":[[0,\"\\n      Cancel\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"button\",[],[[\"@class\",\"@name\",\"@onClick\"],[\"m-8\",\"save-order-settings\",[23,0,[\"onSave\"]]]],{\"statements\":[[0,\"\\n      Done\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/order-settings.hbs"
    }
  });

  let OrderSettingsComponent = (_class = (_temp = class OrderSettingsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "defaultOrderDuration", _descriptor, this);

      _initializerDefineProperty(this, "defaultClearingAccount", _descriptor2, this);

      _initializerDefineProperty(this, "defaultOrderSize", _descriptor3, this);

      _initializerDefineProperty(this, "clearingAccounts", _descriptor4, this);

      _initializerDefineProperty(this, "sessionApi", _descriptor5, this);

      _initializerDefineProperty(this, "notification", _descriptor6, this);

      _initializerDefineProperty(this, "currentUser", _descriptor7, this);

      this.clearingAccounts = this.currentUser.clearingAccounts.map(account => {
        return {
          value: account.t7_account,
          label: account.t7_account
        };
      });

      if (!this.args.orderSettings) {
        this.defaultOrderDuration = this.orderDurations[0].value;
        this.defaultClearingAccount = this.clearingAccounts[0].value;
        this.defaultOrderSize = 1;
      } else {
        let tab = this.args.orderSettings;
        this.defaultOrderDuration = tab.default_order_duration || this.orderDurations[0].value;
        this.defaultClearingAccount = tab.default_clearing_account || this.clearingAccounts[0].value;
        this.defaultOrderSize = tab.default_order_size || 1;
      }
    }

    get orderDurations() {
      return (0, _orderEnums.orderDurations)();
    }

    onCloseOrderSettings() {
      this.args.onClose();
    }

    async onSave() {
      let data = {
        default_order_duration: this.defaultOrderDuration,
        default_clearing_account: this.defaultClearingAccount,
        default_order_size: this.defaultOrderSize
      };

      if (this.args.onUpdate) {
        this.args.onUpdate(data);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "defaultOrderDuration", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "defaultClearingAccount", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "defaultOrderSize", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "clearingAccounts", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onCloseOrderSettings", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseOrderSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSave", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype)), _class);
  _exports.default = OrderSettingsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OrderSettingsComponent);
});