define("incubex/services/sockets", ["exports", "incubex/types/socket", "ember-get-config"], function (_exports, _socket, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    socketHost
  } = _emberGetConfig.default;
  let SocketsService = (_class = (_temp = class SocketsService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      this.socket = null;
      this.connections = [];
    }

    connect() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : socketHost;
      let subs = arguments.length > 1 ? arguments[1] : undefined;
      // url = url + "?access_token=" + encodeURI(this.sessionApi.sessionAuthToken);
      url = url + '?access_token=' + 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEsImp0aSI6IjIwMTktMTItMDVUMTc6MzY6MzUuMTM2OTg1MDkwIiwidDdVc2VyIjoxMjMsInQ3VXNlclBhc3N3b3JkIjoicGFzc3dvcmQiLCJ0N0J1c2luZXNzVW5pdCI6ImxvY2FsX3Nlc3Npb24ifQ.o-VAWmC_40fNioEYQGQEBoLnm30Zc0HCTe6IwbhHtEM';
      this.socket = new _socket.default(url, subs);
      this.connections.pushObject(this.socket);
      return this.socket;
    }

    disconnect(socket) {
      socket.close();
      this.connections.removeObject(socket);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = SocketsService;
});