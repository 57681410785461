define("incubex/controllers/onboard/two-factor/index", ["exports", "incubex/utils/input-validators"], function (_exports, _inputValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OnboardTwoFactorIndexController = (_class = (_temp = class OnboardTwoFactorIndexController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _initializerDefineProperty(this, "onboarding", _descriptor2, this);

      _initializerDefineProperty(this, "phoneNumber", _descriptor3, this);

      _initializerDefineProperty(this, "phoneError", _descriptor4, this);
    }

    get isValidPhoneNumber() {
      return (0, _inputValidators.validatePhoneNumber)(this.phoneNumber);
    }

    onValidatePhoneNumber() {
      this.phoneError = !this.isValidPhoneNumber;
    }

    async sendAuthorizationCode() {
      try {
        let data = {
          phoneNumber: this.phoneNumber
        };
        await this.onboarding.sendAuthCode(data);
        this.onboarding.savePhoneToStorage(this.phoneNumber);
        this.notification.success("An authentication code has been sent to ".concat(this.phoneNumber));
        this.transitionToRoute('onboard.two-factor.verify');
      } catch (e) {
        this.notification.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onboarding", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "phoneNumber", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "phoneError", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onValidatePhoneNumber", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onValidatePhoneNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendAuthorizationCode", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "sendAuthorizationCode"), _class.prototype)), _class);
  _exports.default = OnboardTwoFactorIndexController;
});