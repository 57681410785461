define("incubex/helpers/if-else", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function ifNot(_ref) {
    let [condition, value1, value2] = _ref;

    if (condition) {
      return value1;
    } else {
      return value2;
    }
  });

  _exports.default = _default;
});