define("incubex/tailwind/plugins/utilities/transition", [], function () {
  "use strict";

  /*global module*/
  module.exports = function () {
    return function (_ref) {
      let {
        addUtilities
      } = _ref;
      const transitions = {
        '.transition': {
          transition: 'all 250ms ease-in-out'
        },
        '.transition-500': {
          transition: 'all 500ms ease-in-out'
        }
      };
      addUtilities(transitions);
    };
  };
});