define("incubex/router", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _emberGetConfig.default.locationType,
    rootURL: _emberGetConfig.default.rootURL
  });
  Router.map(function () {
    this.route('freestyle');
    this.route('login');
    this.route('password-reset');
    this.route('password-update');
    this.route('authenticated', {
      path: ''
    }, function () {
      this.route('settings');
      this.route('new-tab');
      this.route('edit-tab', {
        path: 'edit-tab/:tab_id'
      });
      this.route('time-sales-tab');
    });
    this.route('onboard', function () {
      this.route('add-credentials');
      this.route('complete');
      this.route('create-password');
      this.route('start');
      this.route('two-factor', function () {
        this.route('verify');
        this.route('recovery-codes');
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});