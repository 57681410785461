define("incubex/services/password-manager", ["exports", "incubex/types/url", "incubex/utils/input-validators"], function (_exports, _url, _inputValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const RESET_PASSWORD_TOKEN = 'reset_password_token';
  let PasswordService = (_class = (_temp = class PasswordService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "notification", _descriptor2, this);

      _initializerDefineProperty(this, "passwordConfirmation", _descriptor3, this);

      _initializerDefineProperty(this, "passwordConfirmationError", _descriptor4, this);

      _initializerDefineProperty(this, "passwordConfirmationSuccess", _descriptor5, this);

      _initializerDefineProperty(this, "password", _descriptor6, this);

      _initializerDefineProperty(this, "passwordError", _descriptor7, this);
    }

    get passwordConfirmationMessage() {
      if (this.passwordConfirmationError) {
        return 'Passwords do not match.';
      } else if (this.passwordConfirmationSuccess) {
        return 'Passwords match.';
      } else {
        return null;
      }
    }

    get hasMinimumCharacters() {
      return (0, _inputValidators.validateLength)(this.password, 10);
    }

    get isPasswordMatch() {
      return this.password && this.password === this.passwordConfirmation;
    }

    get isValidPassword() {
      return this.hasMinimumCharacters;
    }

    checkPasswordMatch() {
      if (this.passwordConfirmation) {
        this.passwordConfirmationError = !this.isPasswordMatch;
        this.passwordConfirmationSuccess = this.isPasswordMatch;
      } else {
        this.passwordConfirmationError = false;
        this.passwordConfirmationSuccess = false;
      }
    }

    loadToken() {
      let params = new URLSearchParams(document.location.search);
      let token = params.get(RESET_PASSWORD_TOKEN);

      if (token) {
        this.token = token;
      }
    }

    async updatePassword() {
      try {
        let data = {
          user: {
            password: this.password,
            passwordConfirmation: this.passwordConfirmation,
            resetPasswordToken: this.token
          }
        };
        await this.api.put(_url.default.USER_PASSWORD, data);
      } catch (error) {
        // TODO: below error message is temporary only, once we have error/errors property in response, we will remove it.
        // PR #42
        throw Error('Your password could not be reset.');
      }
    }

    async requestResetPasswordOTP() {
      const data = {
        token: this.token
      };
      await this.api.post(_url.default.USER_PASSWORD_RESET_OTP, data);
    }

    async verifyAuthCode(authCode) {
      const data = {
        token: this.token,
        code: authCode
      };
      await this.api.post(_url.default.USER_PASSWORD_RESET_OTP_VERIFY, data);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "passwordConfirmation", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "passwordConfirmationError", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "passwordConfirmationSuccess", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "password", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "passwordError", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class);
  _exports.default = PasswordService;
});