define("incubex/services/block-trade-socket", ["exports", "incubex/types/socket", "ember-get-config", "moment"], function (_exports, _socket, _emberGetConfig, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    blockTradeSocketHost
  } = _emberGetConfig.default;
  let BlockTradeSocketService = (_class = (_temp = class BlockTradeSocketService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      _initializerDefineProperty(this, "notification", _descriptor2, this);

      _initializerDefineProperty(this, "time", _descriptor3, this);

      _initializerDefineProperty(this, "instrument", _descriptor4, this);

      _initializerDefineProperty(this, "currentUser", _descriptor5, this);

      this.socket = null;
      this.connections = [];
      this.trades = [];
      this.tradeHandlers = [];
    }

    connect() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : blockTradeSocketHost;
      let subs = arguments.length > 1 ? arguments[1] : undefined;

      if (!blockTradeSocketHost) {
        return;
      }

      url = url + "?access_token=" + encodeURI(this.sessionApi.sessionAuthToken);
      this.socket = new _socket.default(url, subs);
      this.connections.pushObject(this.socket);
      this.socket.on('open', () => {
        console.log('Block Trade socket created');
        const msg = {
          command: 'subscribe',
          identifier: JSON.stringify({
            channel: 'BlockTradeNotificationsChannel'
          })
        };
        this.socket.send(JSON.stringify(msg));
      });
      this.socket.on('close', () => {
        console.log('Block Trade socket closed');
      });
      this.socket.on('error', error => {
        console.error(error); //TODO: Enable when BlockTrades are working
        // this.notification.error(`Error occured on Block Trade socket`);
      });
      this.socket.on('message', this.onBlockTradeData.bind(this));
    }

    disconnect() {
      if (!blockTradeSocketHost) {
        return;
      }

      this.socket.close();
      this.connections.removeObject(this.socket);
    }

    on(eventType, callback) {
      this.socket.on(eventType, callback);
    }

    subscribe(callback) {
      this.handlers.push(callback);
    }

    onTradeMessage(callback) {
      this.tradeHandlers.push(callback);
    }

    async onBlockTradeData(msg) {
      console.log("BlockTrade Msg:", msg);
      let data = JSON.parse(msg.data);

      if (data) {
        let msgType = data.type ? data.type : data.message ? data.message.type : "";

        switch (msgType.toUpperCase()) {
          case 'PING':
          case 'WELCOME':
            // Ignore
            break;

          case 'BLOCKTRADE':
            {
              let trade = await this.convertToUITrade(data);

              if (trade) {
                this.trades.push(trade);
                this.tradeHandlers.forEach(callback => {
                  if (callback) {
                    callback(trade);
                  }
                });
              }

              break;
            }

          case 'DISABLEUSER':
            if (data.message.message.email == this.currentUser.user.email) {
              this.notification.error("Your account is currently disabled. Please contact IncubEx support for assistance.");
              this.currentUser.logout();
            }

            break;

          default:
            console.log('Unknown block trade message', msg);
        }
      } else {
        console.log('Invalid block trade message', msg);
      }
    }

    async convertToUITrade(data) {
      if (data.message && data.message.message) {
        let msg = data.message.message;
        let trade = {
          product: {
            group: 'undefined'
          }
        };
        let f_o = msg['put_call'];
        trade.product.trade_type = 'BLOCK';
        let p_c = null;

        switch (f_o) {
          case 'C':
          case 'P':
            trade.product.product_type = 'OPTIONS';
            p_c = f_o.toUpperCase();
            break;

          default:
            trade.product.product_type = 'FUTURE';
            break;
        }

        trade.size = msg.volume;
        trade.price = msg.trade_price;
        let expiry = msg["expiration_date"];
        trade.display_expiration = (0, _moment.default)(expiry).format('MMM YY');
        trade.time = (0, _moment.default)(msg['timestamp']).tz(this.time.userZoneName).format('MM/DD/YYYY hh:mm A');
        trade.product.short_name = this.getReferenceValue(msg.product, 'short_name', msg.contract_name, msg.contract_code || '');
        trade.product.group = this.getReferenceValue(msg.product, 'group', msg.product_type, '');
        trade.marketSegmentId = this.getReferenceValue(msg.product, 'market_segment_id', '');
        trade.securityId = this.getReferenceValue(msg.security_definition, 'security_id', '');

        if (f_o) {
          let strikePrice = msg['strike_price'] || '';
          trade.product.short_name = "".concat(trade.product.short_name, " Option ").concat(p_c).concat(strikePrice);
        }

        let instrument = await this.instrument.instrument(trade.marketSegmentId, trade.securityId);
        trade.price = this.instrument.toFixed(parseFloat(trade.price), instrument);
        trade.instrument = instrument;
        return trade;
      }

      return null;
    }

    getReferenceValue(reference, referenceField, feedValue, fallbackValue) {
      if (reference && reference[referenceField]) {
        return reference[referenceField];
      } else {
        return feedValue || fallbackValue || '';
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "time", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "instrument", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = BlockTradeSocketService;
});