define("incubex/services/portfolio-tabs-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PORTFOLIO_ID_SELECTED = 'portfolio_id_selected';
  const TNS_ID_SELECTED = 'tns_id_selected';

  class PortfolioTabsServiceService extends Ember.Service {
    constructor() {
      super(...arguments);
      this.activeTabListeners = [];
      this.activeTnSTabListeners = [];
    }

    addListener(listener) {
      this.activeTabListeners.push(listener);
    }

    addListenerTns(listener) {
      this.activeTnSTabListeners.push(listener);
    }

    onChangeActiveTab(tab, id) {
      this.saveSelectedPortfolioIdToLocalStorage(id);
      this.activeTabListeners.forEach(callback => {
        if (callback) {
          callback(tab);
        }
      });
    }

    onChangeActiveTabTnS(tab, id) {
      this.saveSelectedTnSIdToLocalStorage(id);
      this.activeTnSTabListeners.forEach(callback => {
        if (callback) {
          callback(id);
        }
      });
    }

    saveSelectedPortfolioIdToLocalStorage(id) {
      localStorage.setItem(PORTFOLIO_ID_SELECTED, id);
    }

    getSelectedPortfolioIdFromLocalStorage() {
      return localStorage.getItem(PORTFOLIO_ID_SELECTED);
    }

    saveSelectedTnSIdToLocalStorage(id) {
      localStorage.setItem(TNS_ID_SELECTED, id);
    }

    getSelectedTnSIdFromLocalStorage() {
      return localStorage.getItem(TNS_ID_SELECTED);
    }

  }

  _exports.default = PortfolioTabsServiceService;
});