define("incubex/services/orders-socket", ["exports", "incubex/types/socket", "ember-get-config"], function (_exports, _socket, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    ordersSocketHost
  } = _emberGetConfig.default;
  let OrdersSocketService = (_class = (_temp = class OrdersSocketService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      _initializerDefineProperty(this, "notification", _descriptor2, this);

      this.socket = null;
      this.connections = [];
      this.subscriptions = {};
      this.handlers = [];
      this.orders = [];
      this.pendingSubscriptions = [];
    }

    connect() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ordersSocketHost;
      let subs = arguments.length > 1 ? arguments[1] : undefined;

      if (!ordersSocketHost) {
        return;
      }

      url = url + "?access_token=" + encodeURI(this.sessionApi.sessionAuthToken);
      this.socket = new _socket.default(url, subs);
      this.connections.pushObject(this.socket);
      this.socket.on('open', () => {
        console.log('Orders socket created');
        this.pendingSubscriptions.forEach(subscription => this.send(subscription));
        this.pendingSubscriptions = [];
      });
      this.socket.on('close', () => {
        console.log('Orders socket closed');
      });
      this.socket.on('error', error => {
        console.log(error);
        this.notification.error("Error occured on Orders socket ");
      });
      this.socket.on('message', this.onOrdersData.bind(this)); // TODO: Enable below to send fake data incase you want to simulate data from order-socket without running it
      // setTimeout(this.sendFakeData.bind(this), 3000);
    }

    mockInitiateCancelRequest(orderId) {
      let cancelUpdate = {
        "executionReport": {
          "clOrdId": 1,
          "cumQty": "2",
          "execId": 1584612069000,
          "execType": "4",
          "leavesQty": "3",
          "legs": [],
          "marketSegmentId": 9,
          "ordStatus": "4",
          "orderId": orderId,
          "origClOrdId": 1,
          "securityId": 100,
          "side": '1',
          "triggered": 0
        }
      };
      setTimeout(() => this.onOrdersData(cancelUpdate), 2000);
    }

    disconnect() {
      if (!ordersSocketHost) {
        return;
      }

      this.socket.close();
      this.connections.removeObject(this.socket);
    }

    on(eventType, callback) {
      this.socket.on(eventType, callback);
    }

    onMessage(callback) {
      this.handlers.push(callback);
    }

    send(data) {
      if (this.socket.readyState != WebSocket.OPEN) {
        throw 'Order socket is closed';
      }

      this.socket.send(data);
      console.log('Sent to OrderSocket:', data);
    }

    onOrdersData(msg) {
      if (msg) {
        console.log("OrderMsg", msg);
        let data = msg; // Currently we are pushing mock data ourselves in the UI as well as
        // consuming from the orders socket too for testing purposes.
        //
        // Our mock data structure is different than whats coming from the orders socket,
        // so, until we remove the mock data, we are introducing this
        // extra code that'll allow us to consume both mocked and actual socket data.
        //
        // Convert to JSON when coming from websocket. data will be a string containing JSON

        if (msg.data && typeof msg.data === "string") {
          if (msg.data.toLowerCase().startsWith("ping")) {
            return;
          }

          data = JSON.parse(msg.data);
        }

        this.handlers.forEach(callback => {
          if (callback) {
            callback(data);
          }
        });
      }
    } //
    // Fake Test Data Below
    //


    sendFakeData() {// this.sendFakeInitialOrders();
      // this.sendFakePositionsOnSocketConnect();
      // this.sendFakeOrderUpdates();
      // this.sendFakePositionUpdates();
    }

    sendFakePositionsOnSocketConnect() {
      let data = {
        marketPositions: [{
          marketSegmentId: "220",
          securityId: "4471",
          position: "7.00"
        }, {
          marketSegmentId: "2",
          securityId: "4",
          position: "-1.00"
        }]
      };
      this.onOrdersData(data);
    }

    sendFakePositionUpdates() {
      let data = {
        marketPositions: [{
          marketSegmentId: "220",
          securityId: "4471",
          position: "3.00"
        }]
      };
      setTimeout(() => this.onOrdersData(data), 3000);
    }

    sendFakeInitialOrders() {
      let orders = [{
        "executionReport": {
          "clOrdId": 1,
          "cumQty": "0",
          "execType": "I",
          "marketSegmentId": 9,
          "ordQty": "100",
          "ordStatus": '0',
          "ordType": '2',
          "orderId": '1581536433896000008',
          "price": "1.5",
          "securityId": 100,
          "side": '1',
          "expireDate": "March 11, 2020",
          "timeInForce": "0",
          "trdRegTsEntryTime": "1584699670190",
          "positionEffect": "O"
        }
      }, {
        "executionReport": {
          "clOrdId": 2,
          "cumQty": "30",
          "execType": "I",
          "marketSegmentId": 11,
          "ordQty": "110",
          "ordStatus": '1',
          "ordType": '2',
          "orderId": '1581536433896000009',
          "price": "1.1",
          "securityId": 114,
          "side": '2',
          "expireDate": "March 11, 2020",
          "timeInForce": "6",
          "trdRegTsEntryTime": "1584699670190",
          "positionEffect": "C"
        }
      }, {
        "executionReport": {
          "clOrdId": 3,
          "cumQty": "50",
          "execType": "I",
          "marketSegmentId": 10,
          "ordQty": "50",
          "ordStatus": '2',
          "ordType": '2',
          "orderId": '15815364338960000010',
          "price": "2",
          "securityId": 113,
          "side": '1',
          "expireDate": "March 11, 2020",
          "timeInForce": "5",
          "trdRegTsEntryTime": "1584699670190",
          "positionEffect": "O"
        }
      }, {
        "executionReport": {
          "clOrdId": 4,
          "cumQty": "0",
          "execType": "I",
          "marketSegmentId": 11,
          "ordQty": "150",
          "ordStatus": '9',
          "ordType": '2',
          "orderId": '15815364338960000011',
          "price": "1",
          "securityId": 117,
          "side": '2',
          "expireDate": "March 11, 2020",
          "trdRegTsEntryTime": "1584699670190"
        }
      }, {
        "executionReport": {
          "clOrdId": 5,
          "cumQty": "0",
          "execType": "4",
          "marketSegmentId": 11,
          "ordQty": "150",
          "ordStatus": '4',
          "ordType": '2',
          "orderId": '15815364338960000011',
          "price": "1",
          "securityId": 116,
          "side": '2',
          "expireDate": "March 11, 2020",
          "trdRegTsEntryTime": "1584699670190"
        }
      }];
      orders.forEach(order => this.onOrdersData(order));
    }

    sendFakeOrderUpdates() {
      let updatePrice = {
        "executionReport": {
          "clOrdId": 1,
          "cumQty": "0.0",
          "execType": "5",
          "marketSegmentId": 9,
          "ordQty": "100",
          "ordStatus": '0',
          "ordType": '2',
          "orderId": '1581536433896000008',
          "price": "1.4",
          "securityId": 100,
          "side": '1'
        }
      };
      setTimeout(() => this.onOrdersData(updatePrice), 3000);
      let partialFillUpdate = {
        "executionReport": {
          "clOrdId": 1,
          "cumQty": "2",
          "execId": 1584612069000,
          "execType": "F",
          "fills": [{
            "fillExecId": 100,
            "fillLiquidityInd": 1,
            "fillMatchId": 33554433,
            "fillPx": "1.45",
            "fillQty": "2"
          }],
          "leavesQty": "3",
          "legs": [],
          "marketSegmentId": 9,
          "ordStatus": "1",
          "orderId": '1581536433896000008',
          "origClOrdId": 1,
          "securityId": 100,
          "side": '1',
          "triggered": 0
        }
      };
      setTimeout(() => this.onOrdersData(partialFillUpdate), 5000);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = OrdersSocketService;
});