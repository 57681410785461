define("incubex/components/password-checklist", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class={{@class}} data-ui-component="password-checklist">
    <li class="flex mt-4 items-center" data-role="has-minimum-characters">
      {{svg-jar
        "check-circle"
        class=(if-else this.hasMinimumCharacters "fill-green-500" "fill-gray-600")
        height="14"
        width="14"
      }}
      <span
        {{class
          "ml-4"
          text-green-500=this.hasMinimumCharacters
          text-gray-600=(not this.hasMinimumCharacters)
        }}
      >
        At least 10 characters long
      </span>
    </li>
  </ul>
  
  */
  {
    id: "fQizvVLH",
    block: "{\"symbols\":[\"@class\"],\"statements\":[[7,\"ul\",true],[11,\"class\",[23,1,[]]],[10,\"data-ui-component\",\"password-checklist\"],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"flex mt-4 items-center\"],[10,\"data-role\",\"has-minimum-characters\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"check-circle\"],[[\"class\",\"height\",\"width\"],[[28,\"if-else\",[[23,0,[\"hasMinimumCharacters\"]],\"fill-green-500\",\"fill-gray-600\"],null],\"14\",\"14\"]]],false],[0,\"\\n    \"],[7,\"span\",false],[3,\"class\",[\"ml-4\"],[[\"text-green-500\",\"text-gray-600\"],[[23,0,[\"hasMinimumCharacters\"]],[28,\"not\",[[23,0,[\"hasMinimumCharacters\"]]],null]]]],[8],[0,\"\\n      At least 10 characters long\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/password-checklist.hbs"
    }
  });

  let PasswordChecklistComponent = (_class = (_temp = class PasswordChecklistComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "passwordManager", _descriptor, this);
    }

    get hasMinimumCharacters() {
      return this.passwordManager.hasMinimumCharacters;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "passwordManager", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = PasswordChecklistComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PasswordChecklistComponent);
});