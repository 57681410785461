define("incubex/controllers/onboard/create-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OnboardCreatePasswordController = (_class = (_temp = class OnboardCreatePasswordController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _initializerDefineProperty(this, "onboarding", _descriptor2, this);

      _initializerDefineProperty(this, "passwordManager", _descriptor3, this);

      _initializerDefineProperty(this, "hasAcceptedTerms", _descriptor4, this);

      _initializerDefineProperty(this, "showUserAgreement", _descriptor5, this);
    }

    get isUpdateBtnEnabled() {
      return this.passwordManager.isValidPassword && this.passwordManager.isPasswordMatch && this.hasAcceptedTerms;
    }

    onCheckPasswordMatch() {
      this.passwordManager.checkPasswordMatch();
    }

    async onCreatePassword(event) {
      event.preventDefault();

      try {
        let data = {
          password: this.passwordManager.password,
          confirm_password: this.passwordManager.passwordConfirmation
        };
        await this.onboarding.sendPassword(data);
        this.notification.success('Your password has been saved.');
        this.replaceRoute('onboard.two-factor');
      } catch (e) {
        this.notification.error(e);
      }
    }

    onToggleUserAgreement(bool) {
      this.showUserAgreement = bool;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onboarding", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "passwordManager", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hasAcceptedTerms", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showUserAgreement", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onCheckPasswordMatch", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onCheckPasswordMatch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCreatePassword", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onCreatePassword"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleUserAgreement", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleUserAgreement"), _class.prototype)), _class);
  _exports.default = OnboardCreatePasswordController;
});