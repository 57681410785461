define("incubex/adapters/application", ["exports", "active-model-adapter", "ember-inflector", "ember-simple-auth-token/mixins/token-authorizer", "ember-get-config"], function (_exports, _activeModelAdapter, _emberInflector, _tokenAuthorizer, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const {
    host,
    namespace
  } = _emberGetConfig.default;
  let ApplicationAdapter = (_dec = Ember.computed('session.auth_token'), (_class = (_temp = class ApplicationAdapter extends _activeModelAdapter.default.extend(_tokenAuthorizer.default) {
    constructor() {
      super(...arguments);
      this.host = host;
      this.namespace = namespace;
      this.authorizer = 'authorizer:jwt';
    }

    pathForType(modelName) {
      let underscored = Ember.String.underscore(modelName);
      return (0, _emberInflector.pluralize)(underscored);
    } // Add tokens to the header


    get headers() {
      let data = Ember.get(this, 'session.data.authenticated');
      let token = Ember.get(data, this.tokenPropertyName);
      return {
        [this.authorizationHeaderName]: token
      };
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "headers", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class));
  _exports.default = ApplicationAdapter;
});