define("incubex/tailwind/plugins/components/divider", [], function () {
  "use strict";

  /*global module*/
  module.exports = function () {
    return function (_ref) {
      let {
        addComponents,
        theme
      } = _ref;
      const divider = {
        '.div-dark': {
          borderTopColor: theme('colors.gray.700'),
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          display: 'block'
        },
        '.div-light': {
          borderTopColor: theme('colors.gray.600'),
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          display: 'block'
        }
      };
      addComponents(divider);
    };
  };
});