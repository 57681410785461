define("incubex/services/market-data-socket", ["exports", "incubex/types/socket", "ember-get-config", "incubex/utils/market-data", "incubex/utils/common"], function (_exports, _socket, _emberGetConfig, _marketData, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    socketHost
  } = _emberGetConfig.default;
  let MarketDataSocketService = (_class = (_temp = class MarketDataSocketService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      _initializerDefineProperty(this, "notification", _descriptor2, this);

      _initializerDefineProperty(this, "time", _descriptor3, this);

      _initializerDefineProperty(this, "instrument", _descriptor4, this);

      this.socket = null;
      this.connections = [];
      this.subscriptions = {};
      this.handlers = [];
      this.pendingSubscriptions = [];
      this.trades = [];
      this.tradeHandlers = [];
    }

    connect() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : socketHost;
      let subs = arguments.length > 1 ? arguments[1] : undefined;

      if (!socketHost) {
        return;
      }

      url = url + "?access_token=" + encodeURI(this.sessionApi.sessionAuthToken);
      this.socket = new _socket.default(url, subs);
      this.connections.pushObject(this.socket);
      this.socket.on('open', () => {
        console.log('Market data socket created');
        this.pendingSubscriptions.forEach(subscription => this.send(subscription));
        this.pendingSubscriptions = [];
      });
      this.socket.on('close', () => {
        console.log('Market data socket closed');
      });
      this.socket.on('error', error => {
        console.log(error);
        this.notification.error("Error occured on Market data socket ");
      });
      this.socket.on('message', this.onMarketData.bind(this));
    }

    disconnect() {
      if (!socketHost) {
        return;
      }

      this.socket.close();
      this.connections.removeObject(this.socket);
    }

    on(eventType, callback) {
      this.socket.on(eventType, callback);
    }

    onMessage(callback) {
      this.handlers.push(callback);
    }

    onTradeMessage(callback) {
      this.tradeHandlers.push(callback);
    }

    send(data) {
      if (this.socket.readyState != WebSocket.OPEN) {
        this.pendingSubscriptions.push(data);
        return;
      }

      if (!this.subscriptions[data]) {
        this.subscriptions[data] = {};

        try {
          this.socket.send(data);
          console.log('Subscription created for ' + data);
        } catch (error) {
          console.log(error);
          delete this.subscriptions[data];
        }
      } else {
        // it means subscription is already done and data is also available.
        if (this.subscriptions[data].securityId) return _objectSpread({}, this.subscriptions[data]);
      }
    }

    async onMarketData(msg) {
      if (msg && msg.data) {
        if (msg.data.toLowerCase().startsWith("ping")) {
          return;
        }

        let data = JSON.parse(msg.data);
        let subscriptionId = null;

        if (data.trade) {
          let msg = {
            trade: _objectSpread({}, data.trade)
          };
          let uiTrade = await this.convertToUITrade(msg);
          this.trades.push(uiTrade);
          this.tradeHandlers.forEach(callback => {
            if (callback) {
              callback(uiTrade);
            }
          });
          return;
        }

        if (data.bookSnapshot) {
          let snapshot = data.bookSnapshot;

          let item = this._updateData(snapshot);

          item.marketSegmentId = snapshot.marketSegmentId;
          item.securityId = snapshot.securityId;
          item._children = [];
          item.bids = [];
          item.offers = [];
          item.tradingStatus = (0, _marketData.getTradingStatus)()[snapshot.tradingStatus].toUpperCase();
          item.contractStatus = (0, _marketData.getSecurityStatus)()[snapshot.contractStatus].toUpperCase();
          snapshot.bids.forEach(bid => {
            let priceLevel = bid.priceLevel - 1;
            delete bid.priceLevel;
            item.bids[priceLevel] = _objectSpread({}, bid);
          });
          snapshot.offers.forEach(offer => {
            let priceLevel = offer.priceLevel - 1;
            delete offer.priceLevel;
            item.offers[priceLevel] = _objectSpread({}, offer);
          });
          this.addBidsAndOffersForDisplay(item);
          subscriptionId = item.marketSegmentId + ':' + item.securityId;
          this.subscriptions[subscriptionId] = await this.addPrecision(item);
        }

        if (data.bookIncremental) {
          let update = data.bookIncremental;
          subscriptionId = update.marketSegmentId + ':' + update.securityId;
          let item = this.subscriptions[subscriptionId];

          let newItem = this._updateData(data.bookIncremental, item);

          update.bids.forEach(bid => {
            newItem.bids = this.update(newItem.bids, bid);
          });
          update.offers.forEach(offer => {
            newItem.offers = this.update(newItem.offers, offer);
          });
          this.addBidsAndOffersForDisplay(newItem);
          this.subscriptions[subscriptionId] = await this.addPrecision(newItem);
        }

        this.handlers.forEach(callback => {
          if (callback) {
            callback(this.subscriptions[subscriptionId]);
          }
        });
      }
    }

    addBidsAndOffersForDisplay(item) {
      item._children = [];
      item.bid = null;
      item.bidQty = null;
      item.ask = null;
      item.askQty = null;
      item.bids.forEach((bid, index) => {
        if (index == 0) {
          item.bid = bid.price;
          item.bidQty = bid.size;
        } else {
          index = index - 1;
          item._children[index] = {};
          item._children[index].bid = bid.price;
          item._children[index].bidQty = bid.size;
          item._children[index].level = index + 1;
        }
      });
      item.offers.forEach((offer, index) => {
        if (index == 0) {
          item.ask = offer.price;
          item.askQty = offer.size;
        } else {
          index = index - 1;

          if (!item._children[index]) {
            item._children[index] = {};
          }

          item._children[index].ask = offer.price;
          item._children[index].askQty = offer.size;
          item._children[index].level = index + 1;
        }
      });
      item._children = [...item._children];
    }

    update(data, patch) {
      if (!data) data = [];
      let priceLevel = patch.priceLevel - 1;
      let updateType = patch.updateType;
      delete patch.priceLevel; // don't copy priceLevel to final data, it can be misleading.

      delete patch.updateType;

      switch (updateType) {
        case 0:
          //insert new bookitem at given priceLevel
          data.splice(priceLevel, 0, patch);
          break;

        case 1:
        case 5:
          // update existing bookitem at given priceLevel
          data = data.map((existing, index) => {
            if (index == priceLevel) {
              return Object.assign({}, existing, patch);
            }

            return existing;
          });
          break;

        case 2:
          // delete a book item at given priceLevel
          data.splice(priceLevel, 1);
          break;

        case 3:
          // delete all book items from begining to given priceLevel
          // +1 because index starts at 0;
          data.splice(0, priceLevel + 1);
          break;

        case 4:
          // delete all book items after given priceLevel
          data.length = priceLevel;
          break;
      } // depth of data;


      if (data.length > 10) data.length = 10;
      return data;
    }

    _updateData(data) {
      let item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      item = Object.assign({}, item);
      if (data.volume) item.volume = (0, _common.numberWithCommas)(data.volume);
      if (data.lastPrice) item.lastPrice = data.lastPrice;
      if (data.lastSize) item.lastSize = data.lastSize;
      if (data.openPrice) item.openPrice = data.openPrice;
      if (data.closePrice) item.closePrice = data.closePrice;
      if (data.highPrice) item.highPrice = data.highPrice;
      if (data.lowPrice) item.lowPrice = data.lowPrice;

      if (!item.bids) {
        item.bids = [];
      }

      if (!item.offers) {
        item.offers = [];
      }

      return item;
    }

    async convertToUITrade(data) {
      let id = "".concat(data.trade.marketSegmentId, ":").concat(data.trade.securityId);
      let instrument = await this.instrument.instrument(data.trade.marketSegmentId, data.trade.securityId);

      if (!instrument) {
        instrument = {
          instrument_description: id
        };
      }

      data.trade.time = this.time.convertTimeIntoUserFormat(data.trade.entryTime, true, false, true);
      data.trade = _objectSpread({}, data.trade, {}, instrument);
      data.trade.price = this.instrument.toFixed(data.trade.price, instrument);
      data.trade.product.trade_type = 'SCREEN';
      return data;
    }

    async addPrecision(item) {
      let instrument = await this.instrument.instrument(item.marketSegmentId, item.securityId);
      item.ask = this.instrument.toFixed(item.ask, instrument);
      item.bid = this.instrument.toFixed(item.bid, instrument);
      item.lastPrice = this.instrument.toFixed(item.lastPrice, instrument);
      item.openPrice = this.instrument.toFixed(item.openPrice, instrument);
      item.closePrice = this.instrument.toFixed(item.closePrice, instrument);
      item.highPrice = this.instrument.toFixed(item.highPrice, instrument);
      item.lowPrice = this.instrument.toFixed(item.lowPrice, instrument);

      item._children.forEach(child => {
        if (child.ask) {
          child.ask = this.instrument.toFixed(child.ask, instrument);
        }

        if (child.bid) {
          child.bid = this.instrument.toFixed(child.bid, instrument);
        }
      });

      return item;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "time", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "instrument", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = MarketDataSocketService;
});