define("incubex/components/fills-table", ["exports", "@glimmer/component", "incubex/utils/order-enums"], function (_exports, _component, _orderEnums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberTable @class="fillsTable" as |t|  >
    <t.head @columns={{this.columns}} as |h|>
       <h.row as |r|>
        <r.cell as |column columnMeta|>
          <div class="table-header">
            {{column.name}}
          </div>
          <EmberTh::ResizeHandle @columnMeta={{columnMeta}} />
        </r.cell>
      </h.row>
    </t.head>
    <t.body @class="fill-table" @staticHeight={{true}} @estimateRowHeight=40 @renderAll= {{true}} @rows={{this.rows}} as |b|>
       <b.row as |r|>
        <r.cell as |value column cell column columnMeta|>
          <CellFillsTable @value={{value}} @column={{column}} @cell={{cell}} @columnMeta={{columnMeta}} @onViewFillDetails={{this.viewFillDetailsHandler}}></CellFillsTable>
        </r.cell>
      </b.row>
    </t.body>
  </EmberTable>
  {{#if this.viewFillDetails}}
    <FillDetails @onClose={{action this.hideFillDetails}} @fill={{this.viewFill}} @width="300" ></FillDetails>
  {{/if}}
  
  */
  {
    id: "PGOif+yH",
    block: "{\"symbols\":[\"t\",\"b\",\"r\",\"value\",\"column\",\"cell\",\"column\",\"columnMeta\",\"h\",\"r\",\"column\",\"columnMeta\"],\"statements\":[[5,\"ember-table\",[],[[\"@class\"],[\"fillsTable\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"head\"]],[],[[\"@columns\"],[[23,0,[\"columns\"]]]],{\"statements\":[[0,\"\\n     \"],[6,[23,9,[\"row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,10,[\"cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"table-header\"],[8],[0,\"\\n          \"],[1,[23,11,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[5,\"ember-th/resize-handle\",[],[[\"@columnMeta\"],[[23,12,[]]]]],[0,\"\\n      \"]],\"parameters\":[11,12]}],[0,\"\\n    \"]],\"parameters\":[10]}],[0,\"\\n  \"]],\"parameters\":[9]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[\"@class\",\"@staticHeight\",\"@estimateRowHeight\",\"@renderAll\",\"@rows\"],[\"fill-table\",true,\"40\",true,[23,0,[\"rows\"]]]],{\"statements\":[[0,\"\\n     \"],[6,[23,2,[\"row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"cell-fills-table\",[],[[\"@value\",\"@column\",\"@cell\",\"@columnMeta\",\"@onViewFillDetails\"],[[23,4,[]],[23,5,[]],[23,6,[]],[23,8,[]],[23,0,[\"viewFillDetailsHandler\"]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[4,5,6,7,8]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[4,\"if\",[[23,0,[\"viewFillDetails\"]]],null,{\"statements\":[[0,\"  \"],[5,\"fill-details\",[],[[\"@onClose\",\"@fill\",\"@width\"],[[28,\"action\",[[23,0,[]],[23,0,[\"hideFillDetails\"]]],null],[23,0,[\"viewFill\"]],\"300\"]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/fills-table.hbs"
    }
  });

  let FillsTableComponent = (_class = (_temp = class FillsTableComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "viewFillDetails", _descriptor, this);

      _initializerDefineProperty(this, "viewFill", _descriptor2, this);

      _initializerDefineProperty(this, "time", _descriptor3, this);

      _initializerDefineProperty(this, "columns", _descriptor4, this);
    }

    get rows() {
      let _rows = this.args.fills.map(fill => {
        return {
          short_name: fill.order.short_name,
          price: fill.fill.fillPx,
          side: (0, _orderEnums.toSide)(fill.order.side),
          display_expiration: fill.order.display_expiration,
          qty: parseInt(fill.fill.fillQty),
          orderId: fill.order.orderId,
          status: (0, _orderEnums.toStatus)(fill.order.ordStatus),
          time: this.time.convertTimeIntoUserFormat(fill.order.execId, true, false, true),
          fillId: fill.fill.fillExecId,
          fill: fill
        };
      });

      return _rows.sort(function (a, b) {
        return new Date(b.time) - new Date(a.time);
      });
    }

    viewFillDetailsHandler(fill) {
      if (fill) {
        this.viewFill = fill;
        this.viewFillDetails = true;
      }
    }

    hideFillDetails() {
      this.viewFillDetails = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "viewFillDetails", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "viewFill", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "time", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "columns", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [{
        name: 'Product Name',
        valuePath: 'short_name',
        width: 200
      }, {
        name: 'Expiration',
        valuePath: 'display_expiration',
        width: 70
      }, {
        name: 'Side',
        valuePath: 'side',
        width: 50
      }, {
        name: 'Qty',
        valuePath: 'qty',
        width: 50
      }, {
        name: 'Price',
        valuePath: 'price',
        width: 50
      }, {
        name: "Time (".concat(this.time.timezone, ")"),
        valuePath: 'time',
        width: 200
      }, {
        name: '',
        valuePath: 'options'
      }];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "viewFillDetailsHandler", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "viewFillDetailsHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideFillDetails", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "hideFillDetails"), _class.prototype)), _class);
  _exports.default = FillsTableComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FillsTableComponent);
});