define("incubex/utils/tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildTree = buildTree;
  _exports.getDescendents = getDescendents;
  _exports.getAncestors = getAncestors;
  _exports.getParent = getParent;

  /* Build a tree (nested objects) from a plain array
   * using `id` and `parentId` as references for the
   * relationships. The `name` property is expected
   * for rendering. Optionally, `valueKey` can be
   * passed for `id` and `labelKey` for `name`.
   * If the model is flat, it will return a list.
   */
  function buildTree(model) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let tree = {};
    let roots = Ember.A();

    if (Ember.isEmpty(model)) {
      return roots;
    } // Set defaults and add all nodes to tree


    model.forEach(node => {
      // Alternative name for `id`
      if (options.valueKey) {
        Ember.set(node, 'id', Ember.get(node, options.valueKey));
      } // Alternative name for `name`


      if (options.labelKey) {
        Ember.set(node, 'name', Ember.get(node, options.labelKey));
      } // Defaults


      Ember.set(node, 'children', Ember.A());
      Ember.set(node, 'isChecked', Ember.get(node, 'isChecked') || false);
      Ember.set(node, 'isDisabled', Ember.get(node, 'isDisabled') || false);
      Ember.set(node, 'isExpanded', Ember.get(node, 'isExpanded') || false);
      Ember.set(node, 'isSelected', Ember.get(node, 'isSelected') || false);
      Ember.set(node, 'isVisible', Ember.get(node, 'isVisible') || true);
      tree[Ember.get(node, 'id')] = node;
    }); // Connect all children to their parent

    model.forEach(node => {
      let child = tree[Ember.get(node, options.valueKey || 'id')];
      let parent = Ember.get(node, 'parentId');

      if (Ember.isEmpty(parent)) {
        roots.pushObject(child);
      } else {
        Ember.get(tree[parent], 'children').pushObject(child);
      }
    });
    return roots;
  } // Returns a flat list of all descenents, including the root


  function getDescendents(tree) {
    let depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
    let descendents = Ember.A();

    if (depth < 0) {
      // Unlimited depth
      tree.forEach(node => {
        descendents.pushObject(node);
        descendents.pushObjects(getDescendents(Ember.get(node, 'children')));
      });
    } else if (depth > 0) {
      tree.forEach(node => {
        descendents.pushObject(node);
        descendents.pushObjects(getDescendents(Ember.get(node, 'children'), depth - 1));
      });
    }

    return descendents;
  } // Returns a flat list of ancestors, including the child


  function getAncestors(tree, childNode) {
    let ancestors = Ember.A();
    let childId = Ember.get(childNode, 'id');
    tree.forEach(node => {
      if (!ancestors.isAny('id', childId)) {
        if (Ember.get(node, 'id') === childId) {
          ancestors.pushObject(node);
        } else if (Ember.get(node, 'children.length') > 0) {
          ancestors.pushObjects(getAncestors(Ember.get(node, 'children'), childNode));

          if (ancestors.length > 0) {
            ancestors.pushObject(node);
          }
        }
      }
    });
    return ancestors;
  } // Returns the parent of a child


  function getParent(list, childNode) {
    return list.find(x => x.children.find(y => y.id === Ember.get(childNode, 'id')));
  }
});