define("incubex/services/instrument", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InstrumentService = (_class = (_temp = class InstrumentService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      this.instruments = {};
      this.instruments2 = {};
    }

    async instrument(market_segment_id, security_id) {
      let id = "".concat(market_segment_id, ":").concat(security_id);
      let instrument = this.instruments[id];

      if (instrument) {
        return instrument;
      } else if (!market_segment_id || !security_id) {
        // eslint-disable-next-line no-console
        console.warn("Cannot request for instrument. Invalid market_segment_id or security_id. ID: ".concat(id, "."));
        return null;
      }

      try {
        instrument = await this.sessionApi.get("/security_definitions/by_market_segment_and_security_id?market_segment_id=".concat(market_segment_id, "&security_id=").concat(security_id));
        this.instruments[id] = instrument;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("could not fetch instrument ".concat(id, ". exception is ").concat(e));
        instrument = null;
      }

      return instrument;
    }

    async instrument2(productCode, productType, expiry) {
      let id = "".concat(productCode, ":").concat(productType, ":").concat(expiry);
      let instrument = this.instruments2[id];

      if (instrument) {
        return instrument;
      }

      try {
        instrument = await this.sessionApi.get("/security_definitions/security_by_block_trade?product_code=".concat(productCode, "&product_type=").concat(productType, "&expiry=").concat(expiry));
        this.instruments2[id] = instrument;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("could not fetch instrument ".concat(id, ". exception is ").concat(e));
        instrument = null;
      }

      return instrument;
    }

    toFixed(number, instrument) {
      if (!instrument || !number) return number;
      return parseFloat(number).toFixed(instrument.price_precision);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = InstrumentService;
});