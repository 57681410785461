define("incubex/routes/unauthenticated", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UnauthenticatedRoute extends Ember.Route.extend(_unauthenticatedRouteMixin.default) {
    constructor() {
      super(...arguments);
      this.routeIfAlreadyAuthenticated = 'authenticated';
    }

  }

  _exports.default = UnauthenticatedRoute;
});