define("incubex/components/list-control", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
    {{#each @items as |item|}}
      <div class="flex flex-row">
        <span title="{{item.label}}" class="flex-auto inline-block ml-8 mr-8 p-2 whitespace-no-wrap">{{item.label}}</span>
        <Button
          @style="link"
          @class="mr-8 ml-8"
          @onClick={{action this.removeItem item.id}}
        >
          {{svg-jar "close" height="12" width="12" class="ml-8 font-bold"}}
        </Button>
      </div>
    {{/each}}
  </div>
  
  */
  {
    id: "lwBwbNxG",
    block: "{\"symbols\":[\"item\",\"@items\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"flex flex-row\"],[8],[0,\"\\n      \"],[7,\"span\",true],[11,\"title\",[29,[[23,1,[\"label\"]]]]],[10,\"class\",\"flex-auto inline-block ml-8 mr-8 p-2 whitespace-no-wrap\"],[8],[1,[23,1,[\"label\"]],false],[9],[0,\"\\n      \"],[5,\"button\",[],[[\"@style\",\"@class\",\"@onClick\"],[\"link\",\"mr-8 ml-8\",[28,\"action\",[[23,0,[]],[23,0,[\"removeItem\"]],[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"svg-jar\",[\"close\"],[[\"height\",\"width\",\"class\"],[\"12\",\"12\",\"ml-8 font-bold\"]]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/list-control.hbs"
    }
  });

  let ListControlComponent = (_class = class ListControlComponent extends _component.default {
    removeItem(id) {
      this.args.onRemove(id);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "removeItem", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "removeItem"), _class.prototype)), _class);
  _exports.default = ListControlComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ListControlComponent);
});