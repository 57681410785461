define("incubex/services/current-user", ["exports", "incubex/types/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurrentUserService = (_dec = Ember.computed('user.setting.timezone'), _dec2 = Ember.computed('user.setting.orderConfirmation'), _dec3 = Ember.computed('user.setting.orderCancellationConfirmation'), (_class = (_temp = class CurrentUserService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "sessionApi", _descriptor3, this);

      _initializerDefineProperty(this, "marketDataSocket", _descriptor4, this);

      _initializerDefineProperty(this, "blockTradeSocket", _descriptor5, this);

      _initializerDefineProperty(this, "ordersSocket", _descriptor6, this);

      _initializerDefineProperty(this, "user", _descriptor7, this);

      _initializerDefineProperty(this, "clearingAccounts", _descriptor8, this);
    }

    get settings() {
      return Ember.get(this.user, 'setting');
    }

    set settings(value) {
      if (value.hasDirtyAttributes) {
        this.settings = value;
      }
    }

    get timezone() {
      return Ember.get(this.settings, 'timezone');
    }

    get orderConfirmation() {
      return Ember.get(this.settings, 'orderConfirmation');
    }

    get orderCancelConfirmation() {
      return Ember.get(this.settings, 'orderCancellationConfirmation');
    }

    async load() {
      let id = this.session.data.authenticated.tokenData.sub;
      this.user = await this.store.findRecord('user', id);
      this.settings = await this.store.findRecord('setting', id);
      this.clearingAccounts = await this.sessionApi.get(_url.default.CLEARING_ACCOUNTS);
    }

    logout() {
      this.marketDataSocket.disconnect();
      this.blockTradeSocket.disconnect();
      this.ordersSocket.disconnect();
      this.session.invalidate();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "marketDataSocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "blockTradeSocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "ordersSocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "user", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "clearingAccounts", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "timezone", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "timezone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "orderConfirmation", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "orderConfirmation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "orderCancelConfirmation", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "orderCancelConfirmation"), _class.prototype)), _class));
  _exports.default = CurrentUserService;
});