define("incubex/components/time-sales-data-table", ["exports", "@glimmer/component", "incubex/types/url"], function (_exports, _component, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberTable @name={{this.args.tab.id}} @class="timeTable" as |t|  >
    <t.head @columns={{this.columns}} @onResize ={{action handleColumnResize}} as |h|>
       <h.row as |r|>
        <r.cell as |column columnMeta|>
          <div class="table-header">
            {{column.name}}
          </div>
          <EmberTh::ResizeHandle @columnMeta={{columnMeta}} />
        </r.cell>
      </h.row>
    </t.head>
    <t.body @class="time-table" @estimateRowHeight=40 @rows={{this.args.rows}}  as |b|>
       <b.row as |r|>
        <r.cell @class="nowrap" as |value|>
          {{value}}
        </r.cell>
      </b.row>
    </t.body>
  </EmberTable>
  
  */
  {
    id: "j/DXylGK",
    block: "{\"symbols\":[\"t\",\"b\",\"r\",\"value\",\"h\",\"r\",\"column\",\"columnMeta\"],\"statements\":[[5,\"ember-table\",[],[[\"@name\",\"@class\"],[[23,0,[\"args\",\"tab\",\"id\"]],\"timeTable\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"head\"]],[],[[\"@columns\",\"@onResize\"],[[23,0,[\"columns\"]],[28,\"action\",[[23,0,[]],[24,[\"handleColumnResize\"]]],null]]],{\"statements\":[[0,\"\\n     \"],[6,[23,5,[\"row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,6,[\"cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"table-header\"],[8],[0,\"\\n          \"],[1,[23,7,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[5,\"ember-th/resize-handle\",[],[[\"@columnMeta\"],[[23,8,[]]]]],[0,\"\\n      \"]],\"parameters\":[7,8]}],[0,\"\\n    \"]],\"parameters\":[6]}],[0,\"\\n  \"]],\"parameters\":[5]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[\"@class\",\"@estimateRowHeight\",\"@rows\"],[\"time-table\",\"40\",[23,0,[\"args\",\"rows\"]]]],{\"statements\":[[0,\"\\n     \"],[6,[23,2,[\"row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"cell\"]],[],[[\"@class\"],[\"nowrap\"]],{\"statements\":[[0,\"\\n        \"],[1,[23,4,[]],false],[0,\"\\n      \"]],\"parameters\":[4]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/time-sales-data-table.hbs"
    }
  });

  let TimeSalesDataTableComponent = (_class = (_temp = class TimeSalesDataTableComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      _initializerDefineProperty(this, "notification", _descriptor2, this);

      _initializerDefineProperty(this, "time", _descriptor3, this);

      _initializerDefineProperty(this, "columns", _descriptor4, this);

      this.columnWidthSetting = 'eq-container';
      this.columns = this.getColumns();
    }

    getColumns() {
      let ui_settings = this.args.tab.ui_settings;

      if (!ui_settings) {
        ui_settings = '{}';
      }

      ui_settings = JSON.parse(ui_settings);
      return [{
        name: 'Product Name',
        valuePath: 'short_name',
        width: ui_settings['short_name'] || 220
      }, {
        name: 'Expiration',
        valuePath: 'display_expiration',
        width: ui_settings['display_expiration'] || 80
      }, {
        name: 'Price',
        valuePath: 'price',
        width: ui_settings['price'] || 50
      }, {
        name: 'Volume',
        valuePath: 'volume',
        width: ui_settings['volume'] || 70
      }, {
        name: "Time (".concat(this.time.timezone, ")"),
        valuePath: 'time',
        width: ui_settings['time'] || 150
      }, {
        name: 'Type',
        valuePath: 'type',
        width: ui_settings['type'] || 60
      }];
    }

    async handleColumnResize() {
      let ui_settings = {};
      ui_settings['short_name'] = this.columns.find(item => item.valuePath == 'short_name').width;
      ui_settings['display_expiration'] = this.columns.find(item => item.valuePath == 'display_expiration').width;
      ui_settings['price'] = this.columns.find(item => item.valuePath == 'price').width;
      ui_settings['volume'] = this.columns.find(item => item.valuePath == 'volume').width;
      ui_settings['time'] = this.columns.find(item => item.valuePath == 'time').width;
      ui_settings['type'] = this.columns.find(item => item.valuePath == 'type').width;
      let data = {
        ui_settings: JSON.stringify(ui_settings)
      };

      try {
        await this.sessionApi.put("".concat(_url.default.TIME_AND_SALES, "/").concat(this.args.tab.id), data);
      } catch (e) {
        this.notification.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "time", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "columns", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  })), _class);
  _exports.default = TimeSalesDataTableComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TimeSalesDataTableComponent);
});