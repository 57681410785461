define("incubex/controllers/application", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEV_ENVS = ['development', 'integration'];

  class ApplicationController extends Ember.Controller {
    constructor() {
      super(...arguments);
      this.colorMode = 'default';
    }

    get isDevelopment() {
      return DEV_ENVS.includes(_emberGetConfig.default.environment);
    }

  }

  _exports.default = ApplicationController;
});