define("incubex/controllers/onboard/add-credentials", ["exports", "incubex/utils/input-validators"], function (_exports, _inputValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OnboardAddCredentialsController = (_class = (_temp = class OnboardAddCredentialsController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _initializerDefineProperty(this, "onboarding", _descriptor2, this);

      _initializerDefineProperty(this, "firstName", _descriptor3, this);

      _initializerDefineProperty(this, "lastName", _descriptor4, this);

      _initializerDefineProperty(this, "workPhone", _descriptor5, this);

      _initializerDefineProperty(this, "workPhoneError", _descriptor6, this);

      _initializerDefineProperty(this, "firstNameError", _descriptor7, this);

      _initializerDefineProperty(this, "lastNameError", _descriptor8, this);

      this.workPhoneError = !this.isValidWorkPhone;
    }

    get isSubmitDisabled() {
      return !this.isValidWorkPhone || Ember.isEmpty(this.firstName) || Ember.isEmpty(this.lastName) || Ember.isEmpty(this.workPhone);
    }

    get invalidWorkPhoneErrorMsg() {
      if (Ember.isEmpty(this.workPhone)) return 'Required';
      return 'Phone number is invalid.';
    }

    get isValidWorkPhone() {
      return !Ember.isEmpty(this.workPhone) && (0, _inputValidators.validatePhoneNumber)(this.workPhone);
    }

    onValidatePhoneNumber() {
      this.workPhoneError = !this.isValidWorkPhone;
    }

    onValidateFirstName() {
      this.firstNameError = Ember.isEmpty(this.firstName);
    }

    onValidateLastName() {
      this.lastNameError = Ember.isEmpty(this.lastName);
    }

    async onUpdateCredentials(event) {
      event.preventDefault();

      try {
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          workPhone: this.workPhone
        };
        await this.onboarding.sendCredentials(data);
        this.onboarding.clearToken();
        this.notification.success('Your information has been updated.');
        this.transitionToRoute('onboard.complete');
      } catch (e) {
        this.notification.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onboarding", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "firstName", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "lastName", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "workPhone", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "workPhoneError", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "firstNameError", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.isEmpty(this.firstName);
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "lastNameError", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.isEmpty(this.lastName);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onValidatePhoneNumber", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onValidatePhoneNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onValidateFirstName", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onValidateFirstName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onValidateLastName", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onValidateLastName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateCredentials", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateCredentials"), _class.prototype)), _class);
  _exports.default = OnboardAddCredentialsController;
});