define("incubex/controllers/password-reset", ["exports", "incubex/utils/input-validators", "incubex/types/url"], function (_exports, _inputValidators, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationController = (_class = (_temp = class ApplicationController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "notification", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "email", _descriptor4, this);

      _initializerDefineProperty(this, "emailError", _descriptor5, this);

      _initializerDefineProperty(this, "isResetSentSuccess", _descriptor6, this);
    }

    get isResetDisabled() {
      return !this.isValidEmail || Ember.isEmpty(this.email);
    }

    get isValidEmail() {
      return (0, _inputValidators.validateEmail)(this.email);
    }

    onValidateEmail() {
      return this.emailError = !this.isValidEmail;
    }

    async onReset(event) {
      event.preventDefault();
      let data = {
        user: {
          email: this.email
        }
      };

      try {
        await this.api.post(_url.default.USER_PASSWORD, data);
        this.email = '';
        this.isResetSentSuccess = true;
      } catch (error) {
        this.notification.error(error.errors || error);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "email", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "emailError", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isResetSentSuccess", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onValidateEmail", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onValidateEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onReset", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onReset"), _class.prototype)), _class);
  _exports.default = ApplicationController;
});